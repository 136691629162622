<template>
  <v-container grid-list-md>
    <v-layout wrap>
      <v-flex>
        <v-card elevation="4" width="500" class="mx-auto">
          <v-card-title primary-title>
            <h3 class="headline mb-0">{{ task.name }}</h3>
          </v-card-title>
          <v-divider></v-divider>
          <div>
            <v-card-text>
              <v-layout wrap>
                <v-flex class="mb-3" xs6 sm6 md6>
                  <b>رقم المهمة</b>
                </v-flex>
                <v-flex class="mb-3" xs6 sm6 md6>
                  <strong>{{ task.idtask }}</strong>
                </v-flex>

                <v-flex class="mb-3" xs6 sm6 md6>
                  <b>اسم المهمة</b>
                </v-flex>
                <v-flex class="mb-3" xs6 sm6 md6>
                  <strong>{{ task.name }}</strong>
                </v-flex>

                <v-flex class="mb-3" xs6 sm6 md6>
                  <b>نوع المهمة</b>
                </v-flex>
                <v-flex class="mb-3" xs6 sm6 md6>
                  <strong>{{
                    task.typeId == 1
                      ? "زيارة"
                      : task.typeId == 2
                      ? "تنبيه"
                      : task.typeId == 3
                      ? "طلب معلومات "
                      : "لا يوجد"
                  }}</strong>
                </v-flex>

                <v-flex class="mb-3" xs6 sm6 md6>
                  <b>حالة المهمة</b>
                </v-flex>
                <v-flex class="mb-3" xs6 sm6 md6>
                  <strong>{{
                    task.statusId == 4
                      ? "موكلة"
                      : task.statusId == 3
                      ? "منجزة"
                      : task.statusId == 5
                      ? "بانتظار المراجعة"
                      : task.statusId == 2
                      ? "قيد التقدم"
                      : "لا يوجد"
                  }}</strong>
                </v-flex>

                <v-flex class="mb-3" xs6 sm6 md6>
                  <b>المستخدم المنشئ</b>
                </v-flex>
                <v-flex class="mb-3" xs6 sm6 md6>
                  <strong>{{ userCreator }}</strong>
                </v-flex>

                <v-flex class="mb-3" xs6 sm6 md6>
                  <b>الموكلة إليه</b>
                </v-flex>
                <v-flex class="mb-3" xs6 sm6 md6>
                  <strong>{{ userResponsible }}</strong>
                </v-flex>

                <v-flex class="mb-3" xs6 sm6 md6>
                  <b>تاريخ البداية</b>
                </v-flex>
                <v-flex class="mb-3" xs6 sm6 md6>
                  <strong>{{ createdDate(task.startDate) }}</strong>
                </v-flex>

                <v-flex class="mb-3" xs6 sm6 md6>
                  <b>تاريخ النهاية</b>
                </v-flex>
                <v-flex class="mb-3" xs6 sm6 md6>
                  <strong>{{ createdDate(task.endDate) }}</strong>
                </v-flex>

                <v-flex class="mb-3" xs6 sm6 md6>
                  <b>نوع التكرار</b>
                </v-flex>
                <v-flex class="mb-3" xs6 sm6 md6>
                  <strong>{{
                    task.reapetType == "d"
                      ? "يومي"
                      : task.reapetType == "w"
                      ? "أسبوعي"
                      : task.reapetType == "m"
                      ? "شهري"
                      : task.reapetType == "y"
                      ? "سنوي"
                      : "لا يوجد"
                  }}</strong>
                </v-flex>

                <v-flex class="mb-3" xs6 sm6 md6>
                  <b>عدد مرات التكرار</b>
                </v-flex>
                <v-flex class="mb-3" xs6 sm6 md6>
                  <strong>{{ task.reapets ? task.reapets : "لا يوجد" }}</strong>
                </v-flex>

                <v-flex class="mb-3" xs6 sm6 md6>
                  <b>الملاحظات</b>
                </v-flex>
                <v-flex class="mb-3" xs6 sm6 md6>
                  <strong>{{ task.notes ? task.notes : "لا يوجد" }}</strong>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                v-if="
                  task.creatorId == user.id ||
                  task.responsibleId == user.id ||
                  role == 'Admin'
                "
                style="background: gray; color: white; margin-left: 5px"
                @click="
                  $router.push({
                    name: 'chatting',
                    params: { taskId: task.idtask },
                  })
                "
              >
                <b>المحادثة</b>
              </v-btn>
              <v-btn
                v-if="
                  (role == 'Admin' || task.responsibleId == user.id) &&
                  task.statusId == 4 &&
                  task.typeId != 1
                "
                style="background: #58dd5b; color: white; margin-left: 5px"
                @click="startTask"
              >
                <b>بدء المهمة</b>
              </v-btn>
              <v-btn
                v-if="
                  (role == 'Admin' || task.responsibleId == user.id) &&
                  task.statusId == 2 &&
                  task.typeId != 1
                "
                style="background: #58dd5b; color: white; margin-left: 5px"
                @click="endTask"
              >
                <b>إنهاء المهمة</b>
              </v-btn>
              <!-- <v-btn
                v-if="
                  (task
                    ?task.statusId != 3 &&
                      task.statusId != 5 &&
                      task.responsibleId == user.id
                    : false) && role == 'Admin'
                "
                style="background: #58dd5b; color: white; margin-left: 5px"
                @click="
                  $router.push({
                    name: 'create-visit-report',
                    params: { visitId: visit.idvisit, task: visit }
                  })
                "
              >
                <b>إنشاء تقرير بالزيارة</b>
              </v-btn> -->
              <v-btn
                v-if="
                  (task ? task.statusId != 3 && task.userCreator == user.id : false) ||
                  (task ? task.statusId != 3 :false) &&
                  role == 'Admin'
                "
                style="background: #5867dd; color: white; margin-left: 5px"
                @click="
                  $router.push({ name: 'edit-task', params: { taskId: task.idtask } })
                "
              >
                <b>تعديل</b>
              </v-btn>
              <v-btn @click="$router.go(-1)" style="background: gray" dark>
                <b>رجوع</b>
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      userCreator: "",
      userResponsible: "",
      recId: 0,
      creaId: "",
      task: {
        Name: "",
        TypeId: "",
        ResponsibleId: "",
        CreatorId: "",
        StatusId: "",
        Notes: "",
        StartDate: "",
        EndDate: "",
        ReapetType: "",
        Reapets: "",
      },
      users: [],
    };
  },
  methods: {
    getUsersName() {
      this.users = [];
      this.ApiService.get("auth/listusers")
        .then((res) => {
          this.users = res.data;
          // console.log(res.data);
          this.users.forEach((ele) => {
            // console.log(ele.user.fullName)
            if (ele.user.id === this.task.creatorId) {
              this.recId = ele.user.id;
              this.userCreator = ele.user.fullName;
            }
            if (ele.user.id == this.task.responsibleId) {
              this.userResponsible = ele.user.fullName;
              // console.log(ele.user.fullName);
            }
          });
        })
        .catch(() => {});
    },
    startTask() {
      this.ApiService.put("Task/DoingTasks/" + this.task.idtask)
        .then(() => {
          let msg = "تم بدء المهمة" + this.task.name + "من قبل " + this.userResponsible;
          // console.log(this.user.id, this.recId);
          this.$socket.invoke(
            "NewStatus",
            this.recId,
            this.user.id,
            msg,
            this.task.statusId,
            2,
            this.task.idtask
          );
          this.hel.showSnackMsg("تمت العملية بنجاح", "#58dd5b", 2000);
          this.$router.push({ name: "tasks" });
        })
        .catch(() => {
          this.hel.showSnackMsg("فشلت العملية", "#af1616", 2000);
        });
    },
    endTask() {
      this.ApiService.put("Task/confirmTask/" + this.task.idtask)
        .then(() => {
          let msg = "تم إنهاء المهمة" + this.task.name + "من قبل " + this.userResponsible;
          // console.log(this.user.id,this.recId)
          this.$socket.invoke(
            "NewStatus",
            this.recId,
            this.user.id,
            msg,
            this.task.statusId,
            3,
            this.task.idtask
          );
          this.hel.showSnackMsg("تمت العملية بنجاح", "#58dd5b", 2000);
          this.$router.push({ name: "tasks" });
        })
        .catch(() => {
          this.hel.showSnackMsg("فشلت العملية", "#af1616", 2000);
        });
    },
    async getTask(id) {
      await this.ApiService.get("Task/GetTask?id=" + id)
        .then((res) => {
          this.task = res.data.responseData;
        })
        .catch(() => {});
    },

    createdDate(date) {
      const current_datetime = new Date(date);
      return current_datetime.toLocaleDateString("en-US");
    },
  },
  created() {
    this.user = JSON.parse(window.localStorage.getItem("authUser"));
    this.role = window.localStorage.getItem("roles");
    this.getTask(this.$route.params.taskId);
    this.getUsersName();
  },
};
</script> 

<style lang="scss">
b,
strong {
  font-size: 14px;
}
</style>